<template>
	<section class="domain_content">
		<!-- Disclaimer -->
		<div class="sub-page">
			<h1>Disclaimer</h1>
			<p>
        		The content provided on this website is for general informational purposes only and does not constitute legal, financial, or professional advice. We assume no liability for any direct or indirect losses or damages that may arise from the use or reliance on information provided herein. <br>
WhatsApp enforces strict policies against the use of unauthorized or modified versions of its app, such as GB WhatsApp. If you choose to use such versions, you do so at your own risk, and we are not responsible for any consequences, including the suspension or termination of your WhatsApp account. We strongly recommend using a secondary phone number for these apps and regularly backing up your data to avoid loss.
			</p>
			<div class="part">
				<h2>
          Third-Party Links				
          </h2>
				<p>
          This website may include links to third-party websites for your convenience. We have no control over the content or policies of these external sites and cannot be held responsible for any issues that arise from your use of them. We advise reviewing the privacy policies and terms of service of any third-party websites you visit.
				</p>
				<h2>
          Consent
				</h2>
				<p>
By accessing and using this website, you confirm that you understand and agree to this disclaimer. Your continued use of the site indicates your acceptance of these terms.				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Disclaimer'
const description = 'Visiting this site and reading the disclaimer of this site carefully. '
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://gbwhatsapk.net/disclaimer/"
            }]
	},
}
</script>
  